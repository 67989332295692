@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideFadeBottomToTop {
    0% {
        opacity: 0;
        transform: translateY(rem(25));
    }

    65% {
        opacity: 1;
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideFadeUp {
    0% {
        margin-top: rem(15);
        opacity: 0;
    }

    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes slideFadeUpWithMargin {
    0% {
        margin-top: rem(65);
        opacity: 0;
    }

    100% {
        margin-top: rem(50);
        opacity: 1;
    }
}

@keyframes slideFadeDown {
    0% {
        margin-top: rem(-15);
        opacity: 0;
    }

    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
        margin-top: 3%;
    }

    70% {
        opacity: 1;
    }

    100% {
        margin-top: 0;
    }
}

@keyframes bounce-delay {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(rem(-1), 0, 0);
    }

    20%,
    80% {
        transform: translate3d(rem(2), 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(rem(-4), 0, 0);
    }

    40%,
    60% {
        transform: translate3d(rem(4), 0, 0);
    }
}
